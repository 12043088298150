import React from 'react';

import PropTypes from 'prop-types';

import './news.module.css';
import { graphql, Link } from 'gatsby';
import DefaultLayout from '../layout/DefaultLayout';
import { Icon } from '@mangoart/gatsby-ui/components/ezagrar/Icon';
import { PageTitle } from '@mangoart/gatsby-ui/components/ezagrar/PageTitle';
import { Helmet } from 'react-helmet';
import * as style from 'variowelt.at/src/templates/news.module.css';
import fecha from 'fecha';
import { getImage } from 'gatsby-plugin-image';
import NewsItemImages from '@mangoart/gatsby-ui/components/ezagrar/NewsItemImages';

NewsTemplate.propTypes = {
  pathContext: PropTypes.shape().isRequired,
  t: PropTypes.func.isRequired,
};

function NewsTemplate({ pathContext, data }) {
  const { title, teaser, text, date, images } = data.item;

  const modifiedText = text.replace(/\/storage\//g, 'https://meintraktor.cms.mangomedia.at/storage/');

  const icon = 'news';
  const backToListLabel = 'alle News anzeigen';
  const backToListLink = '/news';

  return (
    <DefaultLayout>
      <article className="defaultWidth">
        <PageTitle>
          <Icon icon={icon} /> News
        </PageTitle>
        <Helmet>
          <title>{`${title} | News`}</title>
          <meta name="description" content={teaser} />
        </Helmet>
        <div className={style.news}>
          <div className={style.right}>
            <h3 style={{ textAlign: 'left', lineHeight: '1.5em' }}>{title}</h3>
            <h4>{fecha.format(new Date(date), 'D.M.YYYY')}</h4>
            <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: modifiedText }} />
            <div className={style.allNewsItemsLink}>
              <Link to={backToListLink}>Zurück zur Liste</Link>
            </div>
          </div>
          <div className={style.left}>
            <NewsItemImages
              images={images.map(({ image }) => {
                return {
                  altText: '',
                  image: getImage(image),
                };
              })}
            />
          </div>
        </div>
      </article>
    </DefaultLayout>
  );
}

export default NewsTemplate;

export const query = graphql`
  query ($id: String) {
    item: cockpitNews(id: { eq: $id }) {
      id
      title
      teaser
      text
      date
      expirationdate
      images {
        image {
          childImageSharp {
            gatsbyImageData(width: 600)
          }
        }
      }
    }
  }
`;
